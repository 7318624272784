import { Component } from '@angular/core';

@Component({
  selector: 'ts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public showMainNav = false;
	public showCookie = false;

	public cookieOff() {
		this.showCookie = false;

    this.setCookie('cookiedisclaimer','accepted', 999);
	}
	
	public toggleMainNav() {
		this.showMainNav = !this.showMainNav;
	}

	private ngOnInit() {
		if (!this.getCookie('cookiedisclaimer')) {
			this.showCookie = true;
		}
	}

	private setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}
	private getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}
}
