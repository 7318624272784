import { Component, OnInit, HostListener } from '@angular/core';
import { filter, map, share, pairwise, startWith } from 'rxjs/operators';
import { RouterAnimations } from '../animations/router.animation';
import { Router, RouterOutlet } from '@angular/router';
import { ItemsService } from '../items.service';
import { ItemsRoutingService } from './items-routing.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'ts-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
  providers: [ItemsRoutingService],
  animations: [
    RouterAnimations.routeSlide
  ]
})
export class ItemsComponent {
  items: any = [];
  itemChange$: Observable<number>;
  next$: Observable<number>;
  prev$: Observable<number>;
  private next:string = '';
  private prev:string = '';
  routeTrigger$: Observable<object>;
  itemsService: ItemsService;

  constructor(itemsService: ItemsService,
              itemsRouting: ItemsRoutingService,
              private router: Router) {
    this.items = itemsService.getItems();
    this.itemsService = itemsService;
    this.itemChange$ = itemsRouting.itemChange$;
    this.setupRouting();
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 39) {
      this.router.navigate(['/portfolio/' + this.next]);
    }
    else if (event.keyCode === 37) {
      this.router.navigate(['/portfolio/' + this.prev]);
    }
  }

  private setupRouting() {
    this.prev$ = this.itemChange$
      .pipe(
        map(index => this.getURLPrev(index, -1)),
        share()
      );
    this.next$ = this.itemChange$
      .pipe(
        map(index => this.getURLNext(index, 1)),
        share()
      );

    this.routeTrigger$ = this.itemChange$
      .pipe(
        startWith(0),
        pairwise(),
        map(([prev, curr]) => ({
          value: curr,
          params: {
            offsetEnter: this.itemsService.getItemByName(prev).index > this.itemsService.getItemByName(curr).index ? -100 : 100,
            offsetLeave: this.itemsService.getItemByName(prev).index > this.itemsService.getItemByName(curr).index ? 100 : -100
          }
        })),
      );
  }

  private getURLPrev(index, direction) {
    var current = this.itemsService.getItemByName(index);
    var prevIndex = current.index + direction;
    if(prevIndex < 0) {
      prevIndex = this.items.length - 1;
    }
    var str = this.itemsService.getItemByIndex(prevIndex).url;
    this.prev = str;
    return str;
  }

  private getURLNext(index, direction) {
    var current = this.itemsService.getItemByName(index);
    var nextIndex = current.index + direction;
    if(nextIndex >= this.items.length) {
      nextIndex = 0;
    }
    var str = this.itemsService.getItemByIndex(nextIndex).url;
    this.next = str;
    return str;
  }

  public getNavVisibility(param) {
    return (param._value === 'home' || 
      param._value === 'vita' ||
      param._value === 'imprint' ||
      param._value === 'privacy-policy');
  }
}
