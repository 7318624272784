import { OnInit, Component, NgModule, Pipe, PipeTransform, ElementRef, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemsService } from '../items.service';
import { ItemsRoutingService } from '../items/items-routing.service';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'ts-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {
  item: any;
  public delayA = false;
  public delayB = false;
  public delayC = false;
  public showBottomContent = false;
  public objectKeys = Object.keys;

  constructor(route: ActivatedRoute,
              itemsService: ItemsService,
              itemsRouting: ItemsRoutingService) {
    const { index } = route.snapshot.params;

    this.item = itemsService.getItemByName(index).item;
    
    itemsRouting.itemChange$.next(index);
  }
  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent(event) {
    // down or up
    if (event.deltaY !== 0 && this.showBottomContent === false) {
      console.log('down');
      this.showBottomContent = true;
    }
  }

  public toggleMore() {
    this.showBottomContent = !this.showBottomContent;
  }

  private ngOnInit() {
    setTimeout(() => {
      this.delayA = true;
    }, 1500);
    setTimeout(() => {
      this.delayB = true;
    }, 2500);
    setTimeout(() => {
      this.delayC = true;
    }, 7000);
  }

  private ngOnDestroy() {

  }

  public getSubtitles(subtitles) {
    var result = subtitles.concat(subtitles);
    result = result.concat(result);

    /*for (var i = result.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = result[i];
        result[i] = result[j];
        result[j] = temp;
    }*/
    return result;
  }

}
