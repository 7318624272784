import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { ItemsComponent } from '../items/items.component';
import { ItemComponent } from '../item/item.component';
import { VitaComponent } from '../vita/vita.component';
import { CustomReuseStrategy } from './route-reuse-strategy';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'portfolio', component: ItemsComponent,
    children: [
      {path: '', redirectTo: 'ufa-fiction', pathMatch: 'full'},
      {path: ':index', component: ItemComponent}
    ]
  },
  {path: ':index', component: ItemsComponent,
    children: [
      {path: '', component: ItemComponent}
    ]
  },
  {path: 'vita', component: VitaComponent, pathMatch: 'full'},
  {path: 'privacy-policy', component: VitaComponent, pathMatch: 'full'},
  {path: 'imprint', component: VitaComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
