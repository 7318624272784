import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vita',
  templateUrl: './vita.component.html',
  styleUrls: ['./vita.component.css']
})
export class VitaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	console.log('init Vita')
  }

}
